// WaGen___HASHKEY__1935df95_2020-06-01 16:29:18 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
未提供注释
*/
/* ---
获取用电类别信息树
*/
export function ydlbTreeService_getYdlbTree(jgbm, meta) {
	return fetch({
		url : 'psdmsqxgl/services/YdlbTreeService/getYdlbTree',
		method : 'post',
		data : {
			param : {
				jgbm : jgbm //String
			}
		}
	})
}

